<template>
    <v-container app fluid>
        <v-row>
            <v-col cols="9" class="pt-1">
                <span class="headline font-weight-medium">{{ title }}</span>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="8" class="pt-0 pb-0">
                <v-container class="pt-0" fluid>
                    <v-row>
                        <v-col cols="12" class="pt-0">
                            <v-card>
                                <v-card-text class="pt-0 pb-0">
                                    <v-container fluid>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field
                                                v-model="name_recipe"
                                                label="Nombre de composición"
                                                outlined
                                                hide-details
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-card>
                                <v-card-text class="pt-0 pb-0">
                                    <v-container fluid>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-autocomplete
                                                    v-model="category_value"
                                                    :items="categories"
                                                    :item-text="customText"
                                                    clearable
                                                    item-value="id"
                                                    outlined
                                                    dense
                                                    label="Seleccione categoria"
                                                    hide-details
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-text-field
                                                    v-model="supply_name"
                                                    label="Producto"
                                                    outlined
                                                    hide-details
                                                    dense                         
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-btn dark color="indigo" @click="handleSearchSupply">
                                                    <v-icon dark class="mr-2" size="14">
                                                        fas fa-search
                                                    </v-icon>
                                                    Mostrar productos
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-card>
                                <v-data-table
                                    :headers="headers"
                                    :items="insumos"
                                    fixed-header
                                    disable-pagination
                                    hide-default-footer
                                    height="48vh"
                                    class="wost-table"
                                >
                                    <template v-slot:item.category_id="{ item }">
                                        <span v-if="item.category">{{ item.category.name }}</span>

                                    </template>

                                    <template v-slot:item.unit_id="{ item }">
                                        <span v-if="item.unit">{{ item.unit.name }}</span>
                                    </template>

                                    <template v-slot:item.actions="{item}">
                                        <v-icon color="green"
                                            size="20"
                                            @click="handleSelectProduct(item)">
                                            fas fa-clipboard-check
                                        </v-icon>
                                    </template>                                    
                                </v-data-table>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>

            <v-col cols="4" class="pt-0 pb-0">
                <v-container fluid class="pt-0 pb-0">
                    <v-row>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-card>
                                <v-card-title class="pt-2 pb-2">
                                    <span class="subtitle-2">Componentes</span>
                                    <v-spacer></v-spacer>
                                    <v-icon>
                                        fas fa-clipboard-list
                                    </v-icon>
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text class="pt-1 pl-1 pr-1 pb-1 list-ingredients">
                                    <v-container>
                                        <v-row v-for="ingredient in ingredients" :key="ingredient.id">
                                            <v-col cols="12" class="pt-1 pb-1">
                                                <v-card>
                                                    <v-card-text class="pt-0 pb-0 pl-1 pr-1">
                                                        <v-list-item two-line dense class="pl-1 pr-1">

                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    <span class="font-italic font-weight-bold">{{ ingredient.quantity }}</span>
                                                                    <span class="ml-1 font-italic font-weight-bold">{{ ingredient.unit_name }}</span>
                                                                </v-list-item-title>
                                                                <v-list-item-subtitle>
                                                                    <span>{{ ingredient.insumo_name }}</span>
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>

                                                            <v-list-item-action>
                                                                <v-btn icon color="red" @click="handleRemoveIngredient(ingredient)">
                                                                    <v-icon size="18">far fa-times-circle</v-icon>
                                                                </v-btn>
                                                            </v-list-item-action>

                                                        </v-list-item>                                            
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-btn block dark color="red" @click="handleCancelSave">
                                Cancelar
                            </v-btn>
                        </v-col>
                        <v-col cols="6">                            
                            <v-btn block dark color="indigo" @click="handleStoreRecipe">
                                Guardar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
                
            </v-col>
        </v-row>

        <v-dialog
            v-model="dialog_supply"
            max-width="450"
        >
            <v-card v-if="supply_selected">
                <v-card-title class="headline pt-3 pb-3">
                    <span class="subtitle-2">Nuevo Ingrediente: {{ supply_selected.name}}</span>
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="4">
                                <v-text-field
                                    ref="quantity"
                                    v-model="formIngredient.quantity"
                                    label="Cantidad"
                                    dense
                                    outlined
                                    :rules="[
                                        () => !!formIngredient.quantity || 'Es necesario ingresar cantidad',
                                        () => (!!formIngredient.quantity && parseFloat(formIngredient.quantity) > 0) || 'Cantidad debe ser mayor a cero'
                                    ]"
                                    hide-details="auto"
                                    required
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="8">
                                <v-text-field v-model="formIngredient.unit_name"
                                    label="Und. medida"
                                    outlined
                                    dense
                                    readonly
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        dark
                        color="red"
                        @click="handleCloseAddIngredient"
                        class="mr-2"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn
                        dark
                        color="green"
                        @click="handleAddIngredient"
                        class="mr-2"
                    >
                        Agregar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
    
</template>

<script>
import { mapState, mapActions } from "vuex";
import router from "@/router/index";
import ArrayTools from "../../../helpers/ArrayTools";
import OfficeLogin from "../../../components/auth/OfficeLogin";
import AddPrices from "../../../components/products/AddPrices";
import AddUnit from "../../../components/products/AddUnit";
import VueBarcode from "@chenfengyuan/vue-barcode";
import Tools from "../../../helpers/Tools";
import SuppliesApi from '../../../apis/Supplies';
import ProductionApi from '../../../apis/Production';

export default {
    name: "CreateUpadate",
    data() {
        return {
            title: null,
            recipe_id: null,
            name_recipe: null,
            category_value: null,
            supply_name: null,
            insumos: [],
            dialog_supply: false,
            supply_selected: null,
            ingredients: [],
            formIngredient: {
                id: null,
                insumo_id: null,
                insumo_name: null,
                quantity: null,
                discount_stock_supply: true,
                unit_id: null,
                unit_name: null,
                cost: null
            },
            headers: [
                { text: 'NOMBRE', value: 'name', class: 'header-table' },
                { text: 'CATEGORIA', value: 'category_id', class: 'header-table' },
                { text: 'UND. MEDIDA', value: 'unit_id', class: 'header-table' },
                /*{ text: 'COLOR', value: 'color_name', class: 'header-table' },
                { text: 'MARCA', value: 'brand_name', class: 'header-table' },
                { text: 'MODELO', value: 'model_name', class: 'header-table' },*/
                { text: '', value: 'actions', sortable: false, width: 40 }
            ],
        }
    },
    computed: {
        ...mapState("categories", ["categories"]),
        ...mapState("brands", ["brands"]),
        ...mapState("products", [
        "selected_product",
        "product",
        "product_prices",
        "products",
        ]),
        ...mapState("units", ["units"]),
    },
    methods: {
        customText(item){
            let text = item.name
            
            return text
        },
        setProduct(product) {
            this.recipe_id = product.id;
            this.name_recipe = product.name;
            let auxIngredients = product.ingredients;
            for (let i = 0; i < auxIngredients.length; i++) {
                const element = {
                    id: auxIngredients[i].id,
                    insumo_id: auxIngredients[i].supply.id,
                    insumo_name: auxIngredients[i].supply.name,
                    quantity: auxIngredients[i].quantity,
                    discount_stock_supply: auxIngredients[i].discount_stock_supply,
                    unit_id: auxIngredients[i].unit_id,
                    unit_name: auxIngredients[i].unit.name,
                    cost: auxIngredients[i].supply.cost
                };
                this.ingredients.push(element);
            }
        },
        handleSelectProduct(product) {
            this.supply_selected = product;
            this.setDataToFormIngredient(this.supply_selected);
            //this.getSubunitsList(this.supply_selected.unit_id);
            this.dialog_supply = true;
        },
        setDataToFormIngredient (data) {
            this.emptyFormIngredient();
            this.formIngredient.insumo_id = data.id;
            this.formIngredient.insumo_name = data.name;
            this.formIngredient.unit_id = data.unit_id;
            this.formIngredient.unit_name = data.unit.name;
        },
        async emptyFormIngredient () {
            this.formIngredient.id = null;
            this.formIngredient.insumo_id = null;
            this.formIngredient.insumo_name = null;
            this.formIngredient.quantity = null;
            this.formIngredient.discount_stock_supply = true;
            this.formIngredient.unit_id = null;
            this.formIngredient.unit_name = null;
            this.formIngredient.cost = null;
            //await this.clearErrors();
        },
        async getProductData(product_search) {
            //await this.show(product_search);
            let itemProduct = JSON.parse(JSON.stringify(this.selected_product));
            console.log(itemProduct);
            this.setProduct(itemProduct);
        },
        async handleSearchSupply() {
            let filters = {
                category_id: this.category_value,
                name: this.supply_name
            }
            //this.getAllProducts(filters);
            await SuppliesApi.get_all(filters)
                .then((result) => {
                    console.log('este es el resultado');
                    console.log(result);
                    console.log('fin');
                    this.insumos = result.data;
                    //this.loading = false; 
                    /*if (this.supply_name){
                        console.log('estoy dentro del if');
                        this.insumos = result.data;
                    }else{
                        console.log('estopy dentro del else');
                        this.insumos = result.data.data;
                    }*/
                    console.log('mis insumos');
                    console.log(this.insumos);
                    
                })
                .catch((errr) => (this.loading = false));
        },
        handleCloseAddIngredient() {
            this.emptyFormIngredient();
            this.supply_selected = null;
            this.closeDialog();
        },
        closeDialog() {
            this.dialog_supply = false;
        },
        handleAddIngredient() {
            if (!this.existErrors()) {
                let today = new Date();
                this.formIngredient.id = today.getTime();
                let item_ingredient = Object.assign({}, this.formIngredient);
                
                this.ingredients.push(item_ingredient);
                this.emptyFormIngredient();
                this.closeDialog();
            }
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.formIngredient).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.formIngredient[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        emptyRecipe() {
            this.name_recipe = null;
            this.ingredients = [];
        },
        handleCancelSave () {
            this.emptyRecipe();
            router.push('/app/logistica/recetas');
        },
        handleRemoveIngredient(ingredient) {
            let index = Tools.findIndexFromItem(this.ingredients, ingredient.id);
            if (index > -1) {
                this.ingredients.splice(index, 1);
            }
        },
        handleStoreRecipe () {
            let recipe = {
                    name: this.name_recipe,
                    ingredients: this.ingredients
                }
            if (this.title == "Nueva receta") {
                
                this.goToSaveRecipe(recipe).then((resp) => {
                    this.store_notification({
                        type: 'success',
                        message: 'Se registro la nueva receta.'
                    });
                }).catch( (error) => {
                    this.store_notification({
                        type: 'error',
                        message: 'Se detectó un error al crear una nueva receta.'
                    });
                });
            } else {
                
                this.goToUpdateRecipe(this.recipe_id, recipe).then((resp) => {
                    this.store_notification({
                        type: 'success',
                        message: 'Se actualizó la receta.'
                    });
                }).catch( (error) => {
                    this.store_notification({
                        type: 'error',
                        message: 'Se detectó un error al actualizar la receta.'
                    });
                });
            }
            
        },
        async goToSaveRecipe(recipe){
            await ProductionApi.saveReceta(recipe);
            this.emptyRecipe();
            this.$notify({
                group: 'foo',
                type: 'success',
                title: 'REGISTRO DE COMPOSICIÓN',
                text: 'Composición registrada de forma correcta.'
            });
        },
        async goToUpdateRecipe(id, recipe){
            await ProductionApi.updateReceta(id, recipe);
            this.emptyRecipe();
            this.$notify({
                group: 'foo',
                type: 'success',
                title: 'REGISTRO DE COMPOSICIÓN',
                text: 'Composición actualizada de forma correcta.'
            });
        },
    },
    created() {
        if (this.selected_product) {
            this.title = "Actualizar datos de receta";
            this.getProductData(this.selected_product);
        } else {
            this.title = "Nueva receta";
            //this.selectProduct(null);
        }
    }
}

</script>

<style scoped>
.list-ingredients{
    height: 67vh !important;
    overflow-y: auto;
}
</style>